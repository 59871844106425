import mitt, { Emitter } from 'mitt';

import { Stack } from './Stack';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface IAction<T = any> {
  redoCallback: (arg: T) => void;
  undoCallback: (arg: T) => void;
  arg: T;
  transactionId: number | null;
}

type HistoryEvent = 'before:undo' | 'before:redo';

/**
 * CMD + Z or CTRL + Z
 */
const isUndoHotkey = (event: KeyboardEvent) =>
  (event.metaKey || event.ctrlKey) && event.code === 'KeyZ' && !event.shiftKey;

/**
 * 1. CMD + SHIFT + Z or CTRL + SHIFT + Z
 * 2. CMD + Y or CTRL + Y
 */
const isRedoHotkey = (event: KeyboardEvent) =>
  (event.metaKey || event.ctrlKey) &&
  ((event.code === 'KeyZ' && event.shiftKey) || event.code === 'KeyY');

export class HistoryManager {
  private redoStack: Stack<IAction>;
  private undoStack: Stack<IAction>;

  /**
   * Set of unique blocker ids to prevent undo/redo
   * When the blockers list is not empty, the undo/redo will be ignored
   */
  private blockers: Set<number> = new Set();

  private isActiveOld = true;

  private transactionId: number | null = null;

  private emitter: Emitter<Record<HistoryEvent, unknown>>;

  get isActive() {
    return this.blockers.size === 0 && this.isActiveOld;
  }

  constructor() {
    this.redoStack = new Stack();
    this.undoStack = new Stack();
    this.emitter = mitt();
    document.addEventListener('keydown', this.onKeydown);
  }

  /**
   * Track the action, adding an item to history
   */
  track<T>(redoCallback: (arg: T) => void, undoCallback: (arg: T) => void, arg: T) {
    this.undoStack.push({ redoCallback, undoCallback, arg, transactionId: this.transactionId });
    this.resetRedoStack();
  }

  /**
   * Execute the action and track it
   * Usable when you want to execute the action and track it at once (to avoid duplicate code)
   */
  execAndTrack<T>(redoCallback: (arg: T) => void, undoCallback: (arg: T) => void, arg: T) {
    redoCallback(arg);
    this.track(redoCallback, undoCallback, arg);
  }

  /**
   * Check if the undo is available
   */
  hasUndo(): boolean {
    return this.undoStack.length > 0;
  }

  /**
   * Check if the redo is available
   */
  hasRedo(): boolean {
    return this.redoStack.length > 0;
  }

  /**
   * Undo the last action
   * Execute the undo callback registered when the action was tracked
   * supplying the provided argument
   */
  undo(): void {
    if (!this.isActive) {
      return;
    }
    this.emitter.emit('before:undo');
    const stackItem = this.undoStack.pop();
    if (!stackItem) {
      return;
    }
    this.redoStack.push(stackItem);
    stackItem.undoCallback(stackItem.arg);
    if (this.hasMoreActionsToUndo(stackItem)) {
      return this.undo();
    }
  }

  /**
   * Redo the last action
   * Execute the redo callback registered when the action was tracked
   * supplying the provided argument
   */
  redo(): void {
    if (!this.isActive) {
      return;
    }
    this.emitter.emit('before:redo');
    const stackItem = this.redoStack.pop();
    if (!stackItem) {
      return;
    }
    this.undoStack.push(stackItem);
    stackItem.redoCallback(stackItem.arg);
    if (this.hasMoreActionsToRedo(stackItem)) {
      return this.redo();
    }
  }

  /**
   * Invalidate the undo history
   */
  resetUndoStack(): void {
    this.undoStack = new Stack();
  }

  /**
   * Invalidate the redo history
   */
  resetRedoStack(): void {
    this.redoStack = new Stack();
  }

  /**
   * Invalidate the undo and redo history
   */
  reset() {
    this.resetUndoStack();
    this.resetRedoStack();
  }

  private onKeydown = (event: KeyboardEvent) => {
    if (isRedoHotkey(event)) {
      event.preventDefault();
      return this.redo();
    }
    if (isUndoHotkey(event)) {
      event.preventDefault();
      return this.undo();
    }
  };

  /**
   * Remove the event listeners
   * Useful in useEffect cleanups
   */
  destroy() {
    document.removeEventListener('keydown', this.onKeydown);
    this.emitter.all.clear();
  }

  /**
   * start the transaction by setting transaction id
   * all the actions tracked during the transaction will be executed at once
   */
  startTransaction({ autoCommit = true }: { autoCommit?: boolean } = {}) {
    if (this.transactionId) {
      throw new Error('HistoryManager transaction is already started');
    }
    this.transactionId = Date.now();
    if (autoCommit) {
      setTimeout(() => {
        this.endTransaction();
      });
    }
  }

  /**
   * commit the transaction by resetting the transaction id
   * all the subsequent actions will be tracked without transaction ID
   */
  endTransaction() {
    this.transactionId = null;
  }

  /**
   * Set the active state
   * true: the undo/redo will be executed
   * false: the undo/redo will be ignored
   * TODO: remove this method after grid changes are in place
   * @deprecated use `const activateCb = pause()` syntax instead
   */
  setIsActive(state: boolean) {
    this.isActiveOld = state;
  }

  /**
   * Add a blocker to prevent undo/redo
   * @returns a function to remove the blocker
   */
  pause() {
    const id = Date.now() + this.blockers.size; // this.blockers.size is used to make the id unique
    this.blockers.add(id);
    return () => {
      this.blockers.delete(id);
    };
  }

  on(event: HistoryEvent, cb: VoidFunction) {
    return this.emitter.on(event, cb);
  }

  off(event: HistoryEvent, cb: VoidFunction) {
    return this.emitter.off(event, cb);
  }

  /**
   * Check if there is more to undo (more actions in the same transaction)
   */
  private hasMoreActionsToUndo(action: IAction): boolean {
    if (!action.transactionId) {
      return false;
    }
    if (!this.hasUndo()) {
      return false;
    }
    const lastAction = this.undoStack[this.undoStack.length - 1];
    return lastAction.transactionId === action.transactionId;
  }

  /**
   * Check if there is more to redo (more actions in the same transaction)
   */
  private hasMoreActionsToRedo(action: IAction): boolean {
    if (!action.transactionId) {
      return false;
    }
    if (!this.hasRedo()) {
      return false;
    }
    const lastAction = this.redoStack[this.redoStack.length - 1];
    return lastAction.transactionId === action.transactionId;
  }
}

import { FieldDTO, FieldType, api } from '@wf-mfe-maestro/api';
import { slice, viewServiceStore } from '@store';

export const updateRecordTypeField = async (fieldData: FieldDTO) => {
  const oldFieldsState = viewServiceStore.getState().fields;
  try {
    viewServiceStore.dispatch(slice.actions.updateRecordTypeField(fieldData));
    const updatedField = await api.field.update(fieldData.id, fieldData);

    if (fieldData.type === FieldType.REFERENCE) {
      const recordType = await api.recordType.get(viewServiceStore.getState().recordType.id);
      viewServiceStore.dispatch(slice.actions.setFields(recordType.fields));
    }
    return updatedField;
  } catch (error) {
    viewServiceStore.dispatch(slice.actions.setFields(oldFieldsState));
    throw error;
  }
};

import { IRootState } from '@store';
import { PayloadAction } from '@reduxjs/toolkit';
import { IFieldFiltering } from '@wf-mfe-maestro/api';

export const setPredefinedFiltersReducer = (
  state: IRootState,
  action: PayloadAction<IFieldFiltering[]>
) => ({
  ...state,
  predefinedFilters: action.payload || [],
});

import { configureStore } from '@reduxjs/toolkit';
import {
  ReactReduxContextValue,
  TypedUseSelectorHook,
  createDispatchHook,
  createSelectorHook,
  createStoreHook,
} from 'react-redux';
import { createContext } from 'react';
import {
  Field,
  IFieldFiltering,
  IFieldType,
  IRecentlyDeletedRecord,
  IView,
  IWorkspace,
} from '@wf-mfe-maestro/api';

import { IPresentUser, IRecordTypeWithoutFields } from '../types';
import { slice } from './slice';

export interface IRootState {
  fields: Field[];
  fieldsMap: Record<string, Field>;
  recordType: IRecordTypeWithoutFields;
  workspace: IWorkspace | null;
  availableFieldTypes: IFieldType[];
  selectedView: IView;
  viewList: IView[];
  fieldsRTBE: {
    added: Field[];
    updated: Field[];
    deleted: string[];
  };
  presenceRTBE: {
    showCollaborators: boolean;
    presentUsers: IPresentUser[];
    presentUsersByRecord: Record<string, IPresentUser[]>;
  };
  recentlyDeletedRecords: IRecentlyDeletedRecord[] | null;
  origin: string;
  predefinedFilters: IFieldFiltering[];
}

export const viewServiceStore = configureStore({
  reducer: slice.reducer,
});

export type Store = ReturnType<typeof viewServiceStore.getState>;

export type AppStore = typeof viewServiceStore;
export type AppDispatch = AppStore['dispatch'];

export const ViewServiceStoreContext = createContext<ReactReduxContextValue | null>(null);

// @ts-expect-error - this is a workaround for a bug in the react-redux types, need to update react-redux version to > 9 to fix this but it requires react 18
export const useStore: () => AppStore = createStoreHook(ViewServiceStoreContext);

// @ts-expect-error - this is a workaround for a bug in the react-redux types, need to update react-redux version to > 9 to fix this but it requires react 18
export const useDispatch: () => AppDispatch = createDispatchHook(ViewServiceStoreContext);

export const useSelector: TypedUseSelectorHook<IRootState> =
  // @ts-expect-error - this is a workaround for a bug in the react-redux types, need to update react-redux version to > 9 to fix this but it requires react 18
  createSelectorHook(ViewServiceStoreContext);

export { slice };

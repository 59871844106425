import { Field, IFieldFiltering, IRecordType, IView, IWorkspace } from '@wf-mfe-maestro/api';

import { ReadonlyField, workspaceSubject$ } from './state';
import { slice, viewServiceStore } from './store';

export const updateRecordTypeState = (recordType: IRecordType) => {
  viewServiceStore.dispatch(slice.actions.updateRecordType(recordType));
};

export const getRecordTypeState = () => viewServiceStore.getState().recordType;

export const updateWorkspaceState = (workspace: IWorkspace) => {
  viewServiceStore.dispatch(slice.actions.updateWorkspace(workspace));
};

export const getWorkspaceState = () => workspaceSubject$.getValue();

export const updateFieldsState = (fields: Field[]) => {
  viewServiceStore.dispatch(slice.actions.setFields(fields));
};

export const getFieldsState = () => ({ fields: viewServiceStore.getState().fields });

export const updateSelectedViewState = (view: IView) => {
  viewServiceStore.dispatch(slice.actions.updateSelectedView(view));
};

export const getSelectedViewState = () => viewServiceStore.getState().selectedView;

export const updateViewListState = (views: IView[]) => {
  viewServiceStore.dispatch(slice.actions.setViewList(views));
};

export const getViewListState = () => viewServiceStore.getState().viewList;

export const subscribeToAddedField = (callback: (fields: Field[]) => void) =>
  viewServiceStore.subscribe(() => {
    if (viewServiceStore.getState().fieldsRTBE.added.length) {
      callback(viewServiceStore.getState().fieldsRTBE.added);
      viewServiceStore.dispatch(slice.actions.resetFieldRTBE());
    }
  });

export const subscribeToUpdatedFields = (callback: (fields: Field[]) => void) =>
  viewServiceStore.subscribe(() => {
    if (viewServiceStore.getState().fieldsRTBE.updated.length) {
      callback(viewServiceStore.getState().fieldsRTBE.updated);
      viewServiceStore.dispatch(slice.actions.resetFieldRTBE());
    }
  });

export const getFieldById = (fieldId: string): ReadonlyField =>
  viewServiceStore.getState().fieldsMap[fieldId];

export const setViewOrigin = (origin: string) => {
  viewServiceStore.dispatch(slice.actions.setOrigin(origin));
};

export const getViewOrigin = () => viewServiceStore.getState().origin;

export const setPredefinedFilters = (predefinedFilters: IFieldFiltering[]) => {
  viewServiceStore.dispatch(slice.actions.setPredefinedFilters(predefinedFilters));
};

export const getPredefinedFilters = () => viewServiceStore.getState().predefinedFilters;

import { IView, api } from '@wf-mfe-maestro/api';
import { slice, viewServiceStore } from '@store';

export const updateCurrentView = async (newData: Partial<IView>, skipApiCall?: boolean) => {
  const selectedView = viewServiceStore.getState().selectedView;

  const updatedData = { ...selectedView, ...newData, updatedAt: new Date().toISOString() } as IView;
  viewServiceStore.dispatch(slice.actions.updateSelectedView(updatedData));

  if (!skipApiCall) {
    return await api.view.update(selectedView.id, updatedData);
  }
};

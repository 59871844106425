import {
  ActionReducerMapBuilder,
  SerializedError,
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit';
import { IRecentlyDeletedRecord, IRecord, api } from '@wf-mfe-maestro/api';
import { IRootState } from '@store/store';

interface IRecentlyDeletedSlice {
  records: IRecentlyDeletedRecord[];
  isLoading: boolean;
  error: SerializedError | null;
}

export interface IRestoreActionPayload {
  recordTypeId: string;
  recordIds: string[];
}

const initialState: IRecentlyDeletedSlice = {
  records: [],
  isLoading: false,
  error: null,
};

export const fetchRecentlyDeletedThunk = createAsyncThunk<IRecentlyDeletedRecord[], string>(
  'viewService/getRecentlyDeleted',
  async (recordTypeId) => api.record.getRecentlyDeleted(recordTypeId)
);

export const restoreRecentlyDeletedThunk = createAsyncThunk<IRecord[], IRestoreActionPayload>(
  'viewService/restoreRecentlyDeleted',
  async ({ recordTypeId, recordIds }, { dispatch }) => {
    const response = await api.record.bulkOperation({
      recordTypeId,
      recordIdsToRestore: recordIds,
    });

    await dispatch(fetchRecentlyDeletedThunk(recordTypeId));

    return response.restores;
  }
);

// TODO: include in the store during the store splitting initiative
export const recentlyDeletedSlice = createSlice({
  name: 'recentlyDeleted',
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(fetchRecentlyDeletedThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchRecentlyDeletedThunk.fulfilled, (state, action) => {
        state.records = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchRecentlyDeletedThunk.rejected, (state, action) => {
        state.error = action.error;
      }),
});

// TODO: tmp: inject thunk handlers. Remove after splitting the store into slices
export const addRecentlyDeletedHandlers = (builder: ActionReducerMapBuilder<IRootState>) => {
  builder.addCase(fetchRecentlyDeletedThunk.fulfilled, (state, action) => {
    state.recentlyDeletedRecords = action.payload;
  });
};

import { getTreatmentIsEnabled } from '@wf-mfe/toggles';

import {
  changeSelectedView as changeSelectedViewRx,
  createRecordTypeField as createRecordTypeFieldRx,
  createView as createViewRx,
  deleteRecordTypeField as deleteRecordTypeFieldRx,
  deleteView as deleteViewRx,
  duplicateView as duplicateViewRx,
  getAllViewByRecordTypeId as getAllViewByRecordTypeIdRx,
  getAvailableFieldTypes as getAvailableFieldTypesRx,
  getNewFieldsAfterCreate as getNewFieldsAfterCreateRx,
  getNewFieldsAfterRemove as getNewFieldsAfterRemoveRx,
  getNewFieldsAfterUpdate as getNewFieldsAfterUpdateRx,
  getRecordType as getRecordTypeRx,
  getView as getViewRx,
  getWorkspace as getWorkspaceRx,
  refreshCurrentView as refreshCurrentViewRx,
  resetViewServiceState as resetViewServiceStateRx,
  updateCurrentView as updateCurrentViewRx,
  updateRecordTypeField as updateRecordTypeFieldRx,
  updateRecordType as updateRecordTypeRx,
  updateTableViewRowOrder as updateTableViewRowOrderRx,
  updateViewData as updateViewDataRx,
  updateView as updateViewRx,
  updateWorkspaceSubject as updateWorkspaceSubjectRx,
} from './service';
import {
  changeSelectedView as changeSelectedViewRedux,
  createRecordTypeField as createRecordTypeFieldRedux,
  createRecordTypeFields as createRecordTypeFieldsRedux,
  createView as createViewRedux,
  deleteRecordTypeField as deleteRecordTypeFieldRedux,
  deleteView as deleteViewRedux,
  duplicateView as duplicateViewRedux,
  getAllViewByRecordTypeId as getAllViewByRecordTypeIdRedux,
  getAvailableFieldTypes as getAvailableFieldTypesRedux,
  getRecordType as getRecordTypeRedux,
  getView as getViewRedux,
  getWorkspace as getWorkspaceRedux,
  updateCurrentView as updateCurrentViewRedux,
  updateRecordTypeField as updateRecordTypeFieldRedux,
  updateRecordType as updateRecordTypeRedux,
  updateTableViewRowOrder as updateTableViewRowOrderRedux,
  updateViewData as updateViewDataRedux,
  updateView as updateViewRedux,
  updateWorkspaceSubject as updateWorkspaceSubjectRedux,
} from './reduxService';

let isMaestroViewServiceReduxEnabled = true;

getTreatmentIsEnabled('maestro-view-service-redux').then((isEnabled) => {
  isMaestroViewServiceReduxEnabled = isEnabled;
});

const rxService = {
  changeSelectedView: changeSelectedViewRx,
  createRecordTypeField: createRecordTypeFieldRx,
  createView: createViewRx,
  deleteRecordTypeField: deleteRecordTypeFieldRx,
  deleteView: deleteViewRx,
  duplicateView: duplicateViewRx,
  getAllViewByRecordTypeId: getAllViewByRecordTypeIdRx,
  getAvailableFieldTypes: getAvailableFieldTypesRx,
  getNewFieldsAfterCreate: getNewFieldsAfterCreateRx,
  getNewFieldsAfterRemove: getNewFieldsAfterRemoveRx,
  getNewFieldsAfterUpdate: getNewFieldsAfterUpdateRx,
  getRecordType: getRecordTypeRx,
  getView: getViewRx,
  getWorkspace: getWorkspaceRx,
  refreshCurrentView: refreshCurrentViewRx,
  resetViewServiceState: resetViewServiceStateRx,
  updateCurrentView: updateCurrentViewRx,
  updateRecordType: updateRecordTypeRx,
  updateRecordTypeField: updateRecordTypeFieldRx,
  updateTableViewRowOrder: updateTableViewRowOrderRx,
  updateView: updateViewRx,
  updateViewData: updateViewDataRx,
  updateWorkspaceSubject: updateWorkspaceSubjectRx,
};

const service = {
  rx: rxService,
  redux: {
    // TODO: update all the functions to use redux
    changeSelectedView: changeSelectedViewRedux,
    createRecordTypeField: createRecordTypeFieldRedux,
    createRecordTypeFields: createRecordTypeFieldsRedux,
    deleteRecordTypeField: deleteRecordTypeFieldRedux,
    deleteView: deleteViewRedux,
    duplicateView: duplicateViewRedux,
    getAllViewByRecordTypeId: getAllViewByRecordTypeIdRedux,
    getAvailableFieldTypes: getAvailableFieldTypesRedux,
    getNewFieldsAfterCreate: () => {},
    getNewFieldsAfterRemove: () => {},
    getNewFieldsAfterUpdate: () => {},
    getRecordType: getRecordTypeRedux,
    getView: getViewRedux,
    getWorkspace: getWorkspaceRedux,
    refreshCurrentView: () => {},
    resetViewServiceState: () => {},
    updateCurrentView: updateCurrentViewRedux,
    updateRecordType: updateRecordTypeRedux,
    updateRecordTypeField: updateRecordTypeFieldRedux,
    updateTableViewRowOrder: updateTableViewRowOrderRedux,
    updateView: updateViewRedux,
    updateViewData: updateViewDataRedux,
    updateWorkspaceSubject: updateWorkspaceSubjectRedux,
    createView: createViewRedux,
  },
};

const getExports = () => {
  const env = isMaestroViewServiceReduxEnabled ? 'redux' : 'rx';
  return service[env];
};

export const changeSelectedView = (...args: Parameters<typeof rxService.changeSelectedView>) =>
  getExports().changeSelectedView(...args);
export const createRecordTypeField = (
  ...args: Parameters<typeof rxService.createRecordTypeField>
) => getExports().createRecordTypeField(...args);
export const createRecordTypeFields = (
  ...args: Parameters<typeof service.redux.createRecordTypeFields>
) => service.redux.createRecordTypeFields(...args);
export const createView = (...args: Parameters<typeof rxService.createView>) =>
  getExports().createView(...args);
export const deleteRecordTypeField = (
  ...args: Parameters<typeof rxService.deleteRecordTypeField>
) => getExports().deleteRecordTypeField(...args);
export const deleteView = (...args: Parameters<typeof rxService.deleteView>) =>
  getExports().deleteView(...args);
export const duplicateView = (...args: Parameters<typeof rxService.duplicateView>) =>
  getExports().duplicateView(...args);
export const getAllViewByRecordTypeId = (
  ...args: Parameters<typeof rxService.getAllViewByRecordTypeId>
) => getExports().getAllViewByRecordTypeId(...args);
export const getAvailableFieldTypes = (
  ...args: Parameters<typeof rxService.getAvailableFieldTypes>
) => getExports().getAvailableFieldTypes(...args);
export const getNewFieldsAfterCreate = (
  ...args: Parameters<typeof rxService.getNewFieldsAfterCreate>
) => getExports().getNewFieldsAfterCreate(...args);
export const getNewFieldsAfterRemove = (
  ...args: Parameters<typeof rxService.getNewFieldsAfterRemove>
) => getExports().getNewFieldsAfterRemove(...args);
export const getNewFieldsAfterUpdate = (
  ...args: Parameters<typeof rxService.getNewFieldsAfterUpdate>
) => getExports().getNewFieldsAfterUpdate(...args);
export const getRecordType = (...args: Parameters<typeof rxService.getRecordType>) =>
  getExports().getRecordType(...args);
export const getView = (...args: Parameters<typeof service.redux.getView>) =>
  getExports().getView(...args);
export const getWorkspace = (...args: Parameters<typeof rxService.getWorkspace>) =>
  getExports().getWorkspace(...args);
export const refreshCurrentView = (...args: Parameters<typeof rxService.refreshCurrentView>) =>
  getExports().refreshCurrentView(...args);
export const updateRecordType = (...args: Parameters<typeof rxService.updateRecordType>) =>
  getExports().updateRecordType(...args);
export const resetViewServiceState = () => getExports().resetViewServiceState();
export const updateCurrentView = (...args: Parameters<typeof service.redux.updateCurrentView>) =>
  getExports().updateCurrentView(...args);
export const updateRecordTypeField = (
  ...args: Parameters<typeof rxService.updateRecordTypeField>
) => getExports().updateRecordTypeField(...args);
export const updateTableViewRowOrder = (
  ...args: Parameters<typeof rxService.updateTableViewRowOrder>
) => getExports().updateTableViewRowOrder(...args);
export const updateView = (...args: Parameters<typeof rxService.updateView>) =>
  getExports().updateView(...args);
export const updateViewData = (...args: Parameters<typeof rxService.updateViewData>) =>
  getExports().updateViewData(...args);
export const updateWorkspaceSubject = (
  ...args: Parameters<typeof rxService.updateWorkspaceSubject>
) => getExports().updateWorkspaceSubject(...args);

export {
  setPresentUsers,
  setShowCollaborators,
  updatePresentUsers,
  getRealTimePresenceData,
  updatePresentUsersByRecord,
} from './reduxService';
